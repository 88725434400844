import { useForm, ValidationError } from "@formspree/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function ContactForm({ buttonText }) {
	const [state, handleSubmit] = useForm("myyqrele");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Control
							className="0 py-3 pb-xl-5 fs-5"
							name="name"
							placeholder="Name"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="phone">
						<Form.Control
							className=" py-3 pb-xl-5 fs-5"
							name="phone"
							placeholder="Phone"
							type="tel"
						/>
					</Form.Group>
					<ValidationError prefix="Phone" field="phone" errors={state.errors} />

					<Form.Group className="mb-3" controlId="email">
						<Form.Control
							className=" py-3 pb-xl-5 fs-5"
							name="_replyto"
							type="email"
							placeholder="Email"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mb-4" controlId="message">
						<Form.Control
							placeholder="Message"
							className="py-3 pb-xl-5 fs-5"
							name="message"
							as="textarea"
							rows={5}
						/>
					</Form.Group>
					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<div className="text-center">
						<Button
							variant="primary"
							className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100 "
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Submit
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Control
					className="0 py-3 pb-xl-5 fs-5"
					name="name"
					placeholder="Name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="phone">
				<Form.Control
					className=" py-3 pb-xl-5 fs-5"
					name="phone"
					placeholder="Phone"
					type="tel"
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="phone" errors={state.errors} />

			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					className=" py-3 pb-xl-5 fs-5"
					name="_replyto"
					type="email"
					placeholder="Email"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-4" controlId="message">
				<Form.Control
					placeholder="Message"
					className="py-3 pb-xl-5 fs-5"
					name="message"
					as="textarea"
					rows={5}
				/>
			</Form.Group>
			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
			<div className="text-center">
				<Button
					variant="primary"
					className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					{buttonText ?? "Submit"}
				</Button>
			</div>
		</Form>
	);
}
export default ContactForm;
